import $ from 'jquery';
import mvSlider from './mvSlider';
import featureSlider from './featureSlider';
import Collapse from './Collapse';
import TabBox from './TabBox';

$(function(){
	"use strict";

	mvSlider();
	featureSlider();

	Collapse.init();
	TabBox.init();

});


var syncerTimeout = null ;


$( function()
{

	$( window ).scroll( function()
	{
		if( syncerTimeout == null )
		{
			syncerTimeout = setTimeout( function(){

				var element = $( '#page-top' ) ;
				var visible = element.is( ':visible' ) ;
				var now = $( window ).scrollTop() ;
				var under = $( 'body' ).height() - ( now + $(window).height() ) ;

				if( now > 400 )
				{
					if( !visible )
					{
						element.fadeIn( 'slow' ) ;
					}
				}

				else if( visible )
				{
					element.fadeOut( 'slow' ) ;
				}

				syncerTimeout = null ;
			} , 400 ) ;
		}
	} ) ;

	$( '#move-page-top' ).click(
		function()
		{
			$( 'html,body' ).animate( {scrollTop:0} , 'slow' ) ;
		}
	) ;
} ) ;

//FAQ用のアコーディオンtoggle
$(function(){
		$("dt#p-faq__title").on("click", function() {
			$(this).next().slideToggle('fast');
			
		});
});

$(function(){
	$('dt#p-faq__title').on('click', function(){
		$(this).toggleClass('refrect-arrow').children('.p-faq_arrow');
	});
});


$(function(){
	$('.p-header__logo a img').hover(function(){
		$(this).attr('src', $(this).attr('src').replace('_off', '_on'));
		}, function(){
		if (!$(this).hasClass('currentPage')) {
		$(this).attr('src', $(this).attr('src').replace('_on', '_off'));
		}
	});
});
