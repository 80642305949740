import $ from 'jquery';

export default function () {
	var $pause = $(".p-mvSlider__button--pause");
	var $play = $(".p-mvSlider__button--play");
	$play.hide();

	var mvSwiper = new Swiper('.p-mvSlider__container', {
		nextButton: '.p-mvSlider__button--next',
		prevButton: '.p-mvSlider__button--prev',
		slidesPerView: 'auto',
		centeredSlides: true,
		spaceBetween: 0,
		loop: true,
		autoplay: 3000,
		autoplayDisableOnInteraction: false

	});

	$pause.on('click', function () {
		mvSwiper.stopAutoplay();
		$pause.hide();
		$play.show();
	});

	$play.on('click', function () {
		mvSwiper.startAutoplay();
		$play.hide();
		$pause.show();
	});
};

