import $ from 'jquery';


export default class CollapseContents {


	constructor($el) {

		this.$el = $el;
		this.state = false;
		this.$target = $el.find('.p-collapseContents__body');
		this.$controller =  $el.find('.p-collapseContents__controller');
		this.initialHeight = this.$target.height();
		this.on();
	}

	on() {
		this.$controller.on('click', this.toggle.bind(this));
	}

	toggle(event) {
		event.preventDefault();
		this.state = !this.state;
		if (this.state) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		var height;
		this.$el.addClass("p-collapseContents--open");
		this.$target.height("");
		height = this.$target.height();
		this.$target.height(this.initialHeight);
		this.$target.height(height);
	}

	close() {
		this.$target.height(this.$target.height());
		this.$target.height(this.initialHeight);
		this.$el.removeClass("p-collapseContents--open");
	}

	static init() {

		$(".p-collapseContents").each(function() {
			return new CollapseContents($(this));
		});
	}

}