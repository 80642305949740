import $ from 'jquery';

export default function () {
	var featureSlider = new Swiper('.p-featureSlider__container', {
		nextButton: '.p-featureSlider__button--next',
		prevButton: '.p-featureSlider__button--prev',
		slidesPerView: 4,
		spaceBetween: 27,
		loop: true,

	});
}

