import $ from 'jquery';


export default class TabBox {


	constructor($el) {

		this.$el = $el;
		this.state = false;
		this.$tabs = $el.find('.c-tabBox__tab');
		this.$panels =  $el.find('.c-tabBox__panel');
		this.initialize();
		this.on();
	}

	initialize() {
		let $target = this.$tabs.eq(0).find('a');
		this.activatePanel( $target );
	}

	on() {
		this.$el.on('click', '.c-tabBox__tab a', this.select.bind(this));
	}

	select(event) {
		event.preventDefault();
		let $target = $(event.currentTarget);
		this.activatePanel( $target );

	}

	activatePanel( $target ) {

		let href = $target.attr('href');
		let $panel = $( href );
		this.$panels.removeClass( 'c-tabBox__panel--active' );
		this.$tabs.removeClass('c-tabBox__tab--active');
		if( $panel.length > 0 ){
			$panel.addClass( 'c-tabBox__panel--active' );
			$target.parent().addClass('c-tabBox__tab--active');
		}
	}


	static init() {
		$(".c-tabBox").each(function() {
			return new TabBox($(this));
		});
	}

}